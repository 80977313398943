@import '../../assets/styles/keyframe.scss';
@import '../../assets/styles/mixin.scss';

.social-list {
    display: block;
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 1000;
    .social-item {
        display: flex;
        align-items: center;
        .social-item-content {
            display: none;
            align-items: center;
            background-color: #ffffffd8;
            font-size: 14px;
            font-weight: 700;
            line-height: 45px;
            padding: 0 60px 0 20px;
            color: #2d314c;
            border-radius: 20px;
            position: absolute;
            z-index: -1;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
            width: 180px;
            white-space: nowrap;
            right: 4px;
            .phone-text {
            }
        }

        .social-item-icon {
            cursor: pointer;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: zoom 1.3s infinite;
            a {
                animation: shake 1s infinite ease-in-out;
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &.zalo {
                box-shadow: 0 0 0 0 #2196f3;
                background-color: #2196f3;
            }

            &.phone {
                box-shadow: 0 0 0 0 #5bb513;
                background-color: #5bb513;
            }
        }

        &:hover {
            .social-item-content {
                display: flex;
            }
        }
    }

    @include Responsive(mobile) {
        right: 20px;
        bottom: 30px;

        .social-item {
            .social-item-content {
                display: none !important;
            }
        }
    }
}
