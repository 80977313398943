.container {
    height: 100vh;
    display: flex;
    .content {
        margin: auto;
        margin-top: 10%;
        h1 {
            font-size: 100px;
            font-weight: 800;
            text-align: center;
        }
        h2 {
            font-size: 35px;
            text-align: center;
            margin-top: -40px;
        }
        p {
            text-align: center;
            font-size: var(--sixteen-px);
        }
        a {
            text-decoration: underline;
            color: red;
        }
    }
}
