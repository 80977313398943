@import '../../../assets/styles/mixin.scss';
.footer-container-content {
    position: relative;
    // background-color: #fff;
    z-index: 1;
    padding-top: 20px;
    background-color: #eafaff;
    .footer-content {
        padding: 0 var(--main-margin);
        margin: auto;
        padding-bottom: 1rem;
        .logo-and-title {
            // border-top: 1px solid var(--primary-color);
            padding-top: 30px;
            display: flex;
            align-items: center;
            .logo-footer {
                width: 160px;
                position: absolute;
                img {
                    width: 100%;
                }
            }
            .title-footer {
                font-size: var(--twenty-px);
                width: 100%;
                font-weight: var(--primary-fontweight);
                text-align: center;
                color: var(--primary-color);
            }
        }
        .content-container {
            display: flex;
            margin-top: 35px;
            .content-des {
                width: 40%;
                div {
                    display: flex;
                    span {
                        font-weight: var(--primary-fontweight);
                        margin-right: 5px;
                        color: var(--primary-color);

                        &.address-text {
                            color: var(--primary-text-color);
                        }
                    }
                    .email {
                        margin-left: 20px;
                    }
                    p {
                        // font-weight: var(--secondary-fontweight);
                        color: var(--primary-text-color);
                    }
                    .logo-notice {
                        display: none;
                        width: 140px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .content-policy {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;

                div {
                    p {
                        color: var(--primary-color);
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        font-size: var(--eighteen-px);
                        font-weight: var(--secondary-fontweight);
                    }
                    ul {
                        li {
                            list-style: none;
                            text-decoration: none;
                        }
                    }
                }
            }

            .content-app {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 30%;
                .app-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        text-transform: uppercase;
                        color: var(--primary-color);
                        margin-bottom: 5px;
                        font-size: var(--eighteen-px);
                        font-weight: var(--secondary-fontweight);
                    }
                    .list-app {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .logo-notice {
                            margin-top: 2rem;
                            display: block;
                            width: 130px;
                            img {
                                width: 100%;
                            }
                        }
                        .download-imedia {
                            margin-top: 4px;
                            display: flex;
                            gap: 15px;
                            div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 94px;
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-footer {
        height: 52px;
        background-color: var(--primary-color);
        width: 100%;

        .footer-bottom-container {
            padding: 0 var(--main-margin);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            .description {
                color: #fff;
                font-size: var(--sixteen-px);
                // font-weight: var(--secondary-fontweight);
            }
            .Footer-ListItem-MeidaSocial {
                display: flex;
                justify-content: flex-start;
                .icon-link-social {
                    width: 42px;
                    height: 42px;
                    svg path {
                        fill: #fff;
                    }
                    &:hover {
                        border-radius: 50%;
                        // background-color: var(--secondary-color);

                        svg path {
                            fill: var(--secondary-color);
                        }
                    }
                }
            }
        }
    }
}

/* CSS cho thiết bị di động */
@include Responsive(mobile) {
    .footer-container-content {
        .footer-content {
            padding-bottom: 0;
            width: 100%;
            .logo-and-title {
                display: block;
                .logo-footer {
                    width: 140px;
                    position: relative;
                }
                .title-footer {
                    margin-top: 10px;
                    font-weight: var(--primary-fontweight);
                    font-size: var(--sixteen-px);
                    text-align: center;
                    margin-bottom: 0;
                }
            }
            .content-container {
                margin-top: 10px;
                display: block;
                .content-des {
                    .notice-imedia {
                        display: none;
                    }
                    width: 100%;
                    .description {
                        margin: 10px 0;
                        span {
                            font-size: var(--fourteen-px);
                        }

                        p {
                            font-size: var(--thirdteen-px);
                        }
                        .notice-imedia {
                            display: none;
                        }
                    }
                }
                .content-policy {
                    width: 100%;
                    div {
                        width: 100%;
                        p {
                            font-size: var(--fourteen-px);
                        }
                        ul {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            li {
                                font-weight: var(--secondary-fontweight);
                                font-size: var(--thirdteen-px);
                                justify-content: space-between;
                                color: var(--primary-text-color);
                                // text-align: center;
                            }
                        }
                    }
                }
                .content-app {
                    width: 100%;
                    display: block;
                    .app-container {
                        p {
                            font-size: var(--fourteen-px);
                        }
                        .list-app {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            div {
                                width: max-content;
                            }
                            .download-imedia {
                                display: flex;
                                div {
                                    width: 82.5px;
                                }
                            }
                            .logo-notice {
                                display: block;
                                margin-top: 0;
                                margin-left: 1.4rem;
                                width: 120px;
                                margin-right: 0;
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-footer {
            height: 30px;
            margin-top: 15px;

            .footer-bottom-container {
                .description {
                    font-size: var(--thirdteen-px);
                    span {
                        display: none;
                    }
                }
                .Footer-ListItem-MeidaSocial {
                    .icon-link-social {
                        width: 30px;
                        height: 30px;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
@include Responsive(tablet) {
    .footer-container-content {
        .footer-content {
            width: 100%;
            padding-bottom: 0;
            .logo-and-title {
                display: block;
                .logo-footer {
                    width: 140px;
                    position: relative;
                    img {
                        width: 100%;
                    }
                }
                .title-footer {
                    text-align: left;
                    margin-top: 10px;
                    font-size: var(--sixteen-px);
                }
            }
            .content-container {
                .content-des {
                    div {
                        span {
                            font-size: var(--fourteen-px);
                        }
                        p {
                            font-size: var(--thirdteen-px);
                        }
                    }
                }
                .content-policy {
                    div {
                        p {
                            font-size: var(--fourteen-px);
                        }
                        .list-policy {
                            font-size: var(--thirdteen-px);
                        }
                    }
                }
                .content-app {
                    .app-container {
                        p {
                            font-size: var(--fourteen-px);
                        }
                        .list-app {
                            .download-imedia {
                                div {
                                    width: 90px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-footer {
            height: 30px;
            margin-top: var(--main-margin);
            .footer-bottom-container {
                .description {
                    font-size: var(--thirdteen-px);
                }
                .Footer-ListItem-MeidaSocial {
                    a {
                        .icon-link-social {
                            width: 30px;
                            height: 30px;
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include Responsive(pc) {
    .footer-container-content {
        .footer-content {
            max-width: var(--width-container);
            margin: auto;
        }
        .footer-bottom-container {
            margin: auto;
            max-width: var(--width-container);
        }
    }
}
