@import '../../assets/styles/mixin.scss';
.heading-news-internal {
    background-color: var(--secondary-color);
    height: 40px;
    position: relative;
    z-index: 1;
    .heading-container {
        height: 100%;
        margin: 0 auto;
        padding: 0 var(--main-margin);
        font-size: var(--sixteen-px);
        color: var(--white-color);
        text-transform: uppercase;
        font-weight: var(--primary-fontweight);
        display: flex;
        align-items: center;
        a {
            display: flex;
            align-items: center;
        color: var(--white-color);
            span {
                position: relative;
                padding: 0 10px;
                &::after {
                    content: '';
                    display: block;
                    height: 17px;
                    position: absolute;
                    right: 0%;
                    width: 1px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: var(--white-color);
                }
            }
        }
        .title {
            margin-left: 10px;
        }
    }
}

@include Responsive(tabletandmobile) {
    .heading-news-internal {
        margin-top: var(--navbarlayout-header-height);
        margin-bottom: -80px;
        .heading-container {
            font-size: var(--fourteen-px);
            a {
                span {
                    padding: 0 5px;
                }
            }
            svg {
                width: 17px;
            }
            .title {
                margin-left: 5px;
            }
        }
    }
}
@include Responsive(pc) {
    .heading-news-internal{
        .heading-container {
            max-width: var(--width-container);
        }
    }
}