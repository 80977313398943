@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Điều chỉnh độ cao của nhảy lên */
    }
}

@keyframes zoom {
    0% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px transparent;
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 transparent;
    }
}

@keyframes shake {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
    }

    10% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    }

    20% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
    }
}
