@import 'normalize.css';
@import '../../assets/styles/mixin.scss';
@import '../../assets/styles/keyframe.scss';

// @import url('https://fonts.cdnfonts.com/css/sf-pro-display?styles=98774,98777,98776,98772,98773,98775,98770,98771,98769');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --main-navbar-color: #eef6f9;
    --main-border-radius: 20px;
    --main-block--distance: 10px;
    --primary-border-radius: 10px;
    --main-margin: 20px;
    --navbarlayout-height: 119px;
    --navbarlayout-header-height: 68px;
    --navbarlayout-height-jsx: 119;
    --navbarlayout-header-height-jsx: 68;
    --primary-color: #415ba9;
    --secondary-color: #e88900;
    --third-color: #794802;
    --primary-fontweight: 600;
    --secondary-fontweight: 500;
    --active-color: #c77600;
    --background-btn-disable: #e0e5f2;
    --color-btn-disable: #878a92;
    --twelve-px: 1.2rem;
    --thirdteen-px: 1.3rem;
    --fourteen-px: 1.4rem;
    --sixteen-px: 1.4rem;
    --eighteen-px: 1.8rem;
    --twenty-one-px: 2.1rem;
    --twenty-px: 2rem;
    // --twenty-four-px: 2.4rem;
    --secondary-text-color: #0d152e;
    --primary-background-color: #f8f8f8;
    --primary-text-color: #3c4252;
    --primary-line-height: 32.4px;
    --white-color: #fff;
    // --width-container: 1600px;
    // --width-container: 1440px;
    --width-container: 1280px;
    --font-size-body: 1.4rem;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    font-size: 56%;
    // scroll-behavior: smooth;
}

body {
    // font-family: 'SF Pro Display', sans-serif;
    font-family: 'Roboto', sans-serif;

    font-size: var(--font-size-body);
    text-rendering: optimizeSpeed;
}
p {
    letter-spacing: 0px;
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--primary-text-color);
    &:hover {
        text-decoration: none;
        color: var(--primary-color);
    }
}

.position-relative {
    position: relative;
}

.content-curved {
    position: absolute;
    right: 0;
    left: 0;
    top: -20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .content-curved-content {
        width: 100%;
        max-width: 1280px;
        height: 20px;
        border-top-left-radius: 500px 13px;
        border-top-right-radius: 500px 13px;
        position: relative;
        background-color: #fff;

        &.white-color {
            background-color: #fff !important;
        }

        &.primary-color {
            background-color: var(--primary-color) !important;
        }
    }
    @include Responsive(pc) {
        .content-curved-content {
            &::after {
                content: '';
                display: block;
                position: absolute;
                background-color: #fff;
                width: 20px;
                height: 20px;
                // left: 0;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                background-color: #fff;
                width: 20px;
                height: 20px;
                right: 0;
            }
        }
    }
    @include Responsive(tabletandmobile) {
        height: 20px;
        top: -18px;
    }
}

.description-content-container {
    text-align: justify;
    line-height: 3rem;

    ul {
        padding-left: var(--main-margin); /* Loại bỏ padding mặc định của danh sách */
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    p {
        // padding-bottom: 1rem;
        margin-bottom: 0.6rem;
        line-height: 2.4rem;
    }
}

.post-content {
    figure {
        max-width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        max-width: 100%;
        margin: 10px 0;
        height: auto !important;
    }

    .text-tiny {
        font-size: calc(var(--font-size-body) - 0.4rem);
    }

    .text-small {
        font-size: calc(var(--font-size-body) - 0.2rem);
    }

    .text-big {
        font-size: calc(var(--font-size-body) + 0.4rem);
    }

    .text-huge {
        font-size: calc(var(--font-size-body) + 0.8rem);
    }

    ul {
        padding-left: var(--main-margin); /* Loại bỏ padding mặc định của danh sách */
    }

    p {
        // padding-bottom: 1rem;
        margin-bottom: 0.6rem;
        line-height: 2.4rem;
    }
}

.pd-ul {
    ul {
        padding-left: var(--main-margin); /* Loại bỏ padding mặc định của danh sách */
    }
}

.sapo-description {
    display: block;
    text-align: justify;
    line-height: 3rem;
    margin: 3rem 0;

    img {
        max-width: 100%;
        height: auto !important;
    }

    ul {
        padding-left: var(--main-margin); /* Loại bỏ padding mặc định của danh sách */
    }

    p {
        // padding-bottom: 1rem;
        margin-bottom: 0.6rem;
        line-height: 2.4rem;
    }
}

.border-radius-container {
    border-radius: 6px;
}

.border-radius-top-x {
    overflow: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @include Responsive(pc) {
        border-bottom-left-radius: var(--primary-border-radius);
        border-bottom-right-radius: var(--primary-border-radius);
    }
}

.border-radius-bottom-x {
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include Responsive(pc) {
        border-bottom-left-radius: var(--primary-border-radius);
        border-bottom-right-radius: var(--primary-border-radius);
    }
}

.disable-radius-bottom-y {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.border-radius-banner {
    @include Responsive(pc) {
        overflow: hidden;
        border-top-left-radius: var(--primary-border-radius);
        border-top-right-radius: var(--primary-border-radius);
    }
}

.border-radius-none {
    border-radius: 0 !important;
}

.custon-btn-slide {
    .slideshow-container {
        .next-btn {
            @include Responsive(desktop) {
                // right: 14px !important;
            }
        }
    }
}

.category-news {
    padding: 10px var(--main-margin);
    max-width: var(--width-container);
    margin: 0 auto;
}

.heading-hover {
    &:hover {
        color: var(--primary-color) !important;
    }
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.mt-2rem {
    margin-top: 2rem !important;
}

.mt-1rem {
    margin-top: 1rem !important;
}

.mb-1rem {
    margin-bottom: 1rem !important;
}

.mb-2rem {
    margin-bottom: 2rem !important;
}

.pd-b-2rem {
    padding-bottom: 2rem !important;
}

.pd-b-1rem {
    padding-bottom: 1rem !important;
}

.pd-t-1rem {
    padding-top: 1rem !important;
}

.fw-normal {
    font-weight: normal !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.err-color {
    color: #e88900 !important;
}

::-webkit-scrollbar {
    width: 0px;
}

// iframe[data-testid='bubble_iframe'] {
//     transform: scale(0.85);
// }
