@import '../../../../assets/styles/mixin.scss';
.navbar-container {
    display: flex;
    padding: 0 var(--main-margin);
    width: 100%;
    height: 100%;
    align-items: center;
    .menu-items {
        margin-right: 25px;
        text-decoration: none;
        color: var(--primary-color);
        // text-transform: uppercase;
        position: relative;
        font-weight: var(--primary-fontweight);
        font-size: var(--sixteen-px);
        &::before {
            content: '';
            position: absolute;
            top: 120%;
            width: 100%;
            height: 1px;
            background-color: var(--secondary-color);
            transform-origin: center;
            transform: scaleX(0);
            transition: transform 0.3s ease-in-out;
        }
        &.active {
            color: var(--secondary-color);
            &::before {
                content: '';
                position: absolute;
                top: 120%;
                width: 100%;
                height: 1px;
                background-color: var(--secondary-color);
                transform-origin: center;
                transform: scaleX(1);
                transition: transform 0.3s ease-in-out;
            }
        }
        &:hover {
            color: var(--active-color);
        }

        &.menu-item-child {
            &.active {
                color: var(--primary-color);
            }
        }

        &.active-custom.menu-item-child.active {
            color: var(--secondary-color) !important;
            &::before {
                content: '';
                position: absolute;
                top: 120%;
                width: 100% !important;
                height: 1px;
                background-color: var(--secondary-color);
                transform-origin: center;
                transform: scaleX(1) !important;
                transition: transform 0.3s ease-in-out;
            }
        }

        &:hover::before {
            transform: scaleX(1);
        }
    }
    .menu-items.social.menu-item-child.active {
        &::before {
            transform: scaleX(0);
        }
        &:hover {
            color: var(--active-color) !important;
            &::before {
                transform: scaleX(1);
            }
        }
    }
    .dropdown {
        position: relative;
        .dropdown-parent {
            position: absolute;
            width: 336px;
            height: 206px;
            top: 34px;
            left: -10%;
            font-weight: 500;
            font-size: var(--sixteen-px);
            background-color: #eff7f9;
            border-radius: 2px;
            height: max-content;
            // box-shadow: -3px 4px 4px 0px #00000040;

            li {
                font-weight: var(--secondary-fontweight);
                width: 100%;
                padding: 10px var(--main-margin);
                z-index: 1;
                color: var(--primary-text-color);
                list-style: none;
                &:hover {
                    background-color: var(--secondary-color);
                    color: #fff;
                    a {
                        color: #fff;
                    }
                }
                a {
                    color: var(--primary-text-color);
                    text-decoration: none;
                    width: 100%;
                    height: 100%;
                }
            }

            .menu-dropdown-child {
                position: relative;
                ul {
                    display: none;
                    position: absolute;
                    width: 336px;
                    height: 206px;
                    top: 0;
                    left: 100%;
                    font-weight: 500;
                    font-size: var(--sixteen-px);
                    background-color: #eff7f9;
                    border-radius: 2px;
                    height: max-content;
                    // box-shadow: 3px 3px 4px 0px #bebebe40;

                    li {
                        font-weight: var(--secondary-fontweight);
                        width: 100%;
                        padding: 10px var(--main-margin);
                        z-index: 1;
                        color: var(--primary-text-color);
                        list-style: none;
                        &:hover {
                            background-color: var(--secondary-color);
                            color: #fff;
                            a {
                                color: #fff;
                            }
                        }
                        a {
                            color: var(--primary-text-color);
                            text-decoration: none;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .dropdown-menu-icon {
                    position: absolute;
                    right: 4px;
                    top: calc(50% - 5px);
                    color: var(--secondary-color);

                    path {
                        // fill: var(--primary-text-color);
                        fill: #878a92;
                    }
                }

                &:hover {
                    ul {
                        display: block;
                    }

                    .dropdown-menu-icon {
                        path {
                            fill: white;
                        }
                    }
                }
            }

            &::before {
                content: '';
                position: absolute;
                background-color: transparent;
                width: 30%;
                height: 25px;
                // top: -7%;
                top: -18px;
                left: 15%;
                transform: translateX(-50%);
            }
            &::after {
                content: '';
                position: absolute;
                display: block;
                border: 16px solid;
                border-color: transparent transparent #fff transparent;
                top: -7%;
                z-index: -1;
                left: 4%;
            }
        }
    }
}

@include Responsive(tabletandmobile) {
    .navbar-container .menu-items.social.menu-item-child.active::before {
        transform: scaleX(0) !important;
    }
    .navbar-container {
        .menu-items {
            margin-right: 5px;
            &::before {
                width: 0;
            }
        }
    }
}
