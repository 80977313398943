@import '../../../assets/styles/mixin.scss';
.NavbarLayout {
    width: 100%;
    height: var(--navbarlayout-height);
    position: relative;
    z-index: 2;
    .NavbarLayout-header-container {
        height: var(--navbarlayout-header-height);
        background-color: var(--main-navbar-color);
        .NavbarLayout-header {
            padding: 0 var(--main-margin);
            height: 100%;
            display: flex;
            align-items: center;
            margin: auto;
            width: 100%;
            .NavbarLayout-logo {
                flex: 2;
                img {
                    width: 100px;
                    // height: 56px;
                }
            }
            .NavbarLayout-logo-imedia {
                display: none;
                flex: 1;

                img {
                    width: 75px;
                    height: 32px;
                }
            }
            .NavbarLayout-HolaItems {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;
                a {
                    margin-left: var(--main-margin);
                    height: 30px;
                    transition: margin-bottom 0.3s ease; /* Thêm transition cho margin-bottom */
                    &:hover {
                        margin-bottom: 5px;
                    }

                    img {
                        height: 24px;
                    }
                }
            }
            .Navbar-mobile-icon {
                cursor: pointer;
                display: none;
                z-index: 10;
            }
        }
    }
    .hidden {
        display: none;
    }
    .NavbarLayout-menu-container {
        height: calc(var(--navbarlayout-height) - var(--navbarlayout-header-height));
        background-color: #fff;
        .NavbarLayout-menu {
            margin: auto;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            .logo-imedia-scroll {
                display: none;
                height: 32px;
                margin-right: 50px;
                img {
                    height: 100%;
                }
            }
            .block {
                display: block;
            }
            .NavabrLayout-ListItem {
                flex: 1;
            }
            .NavbarLayout-ListItem-MeidaSocial {
                display: flex;
                justify-content: flex-start;
                .icon-link-social {
                    // width: 42px;
                    // height: 42px;
                    &:hover {
                        border-radius: 50%;
                        // background-color: var(--secondary-color);
                        svg path {
                            // fill: #fff;
                            fill: var(--secondary-color);
                        }
                    }
                }
            }
        }
        &.box-shadow {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            box-shadow: 0px 2px 4px 0px #00000029;
        }
    }
}
@include Responsive(tabletandmobile) {
    .NavbarLayout {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: max-content;
        .NavbarLayout-header-container {
            background-color: var(--primary-color);
            .NavbarLayout-header {
                .NavbarLayout-logo-imedia {
                    display: block;
                }
                .NavbarLayout-HolaItems {
                    display: none;
                }
                .Navbar-mobile-icon {
                    // position: relative;
                    display: block;
                }
            }
        }
        .NavbarLayout-menu-container {
            display: none;
        }
    }

    .NavbarLayout-logo {
        display: none;
    }
}

@include Responsive(pc) {
    .NavbarLayout {
        .NavbarLayout-header-container {
            width: 100%;
            .NavbarLayout-header {
                max-width: var(--width-container);
            }
        }

        .NavbarLayout-menu {
            max-width: var(--width-container);
        }
    }
}
