.loading-spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    bottom: 0;
    justify-content: center;
    left: 0;
    display: flex;
    z-index: 100000;
}
