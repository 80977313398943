.NavabrLayout-ListItem-Mobile {
    left: 0%;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    top: 90%;
    padding: 5px var(--main-margin);
    background-color: var(--white-color);
    box-shadow: 0px 4px 4px 0px #00000040;
    .menu-items {
        width: 150px;
        color: var(--primary-color);
        font-weight: var(--primary-fontweight);
        font-size: var(--fourteen-px);
        text-transform: uppercase;
        padding: 8px 0;
        display: flex;
        align-items: center;

        &.active {
            color: var(--secondary-color);
            .line-mobile {
                height: 1px;
                flex: 1;
                margin-left: 15px;
                background-color: var(--secondary-color);
            }
        }
        &:hover {
            color: var(--active-color);
        }
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -19%;
        right: 0%;
        border: 36px solid;
        border-color: transparent transparent var(--white-color) transparent;
    }
}
.menu-service {
    top: 85%;
    .menu-items.social.menu-item-child.active {
        .line-mobile {
            display: none !important;
        }
    }
    .menu-items.social.menu-item-child.active.active-custom {
        .line-mobile {
            display: block !important;
        }
    }
    .menu-items {
        border-bottom: none !important;
    }
    .menu-items.active-custom {
        border-bottom: none !important;
    }
}