@import '../../assets/styles/mixin.scss';
.content-imedia {
    position: relative;
}

.Container-body {
    // margin-top: var(--navbarlayout-height);
    min-height: 80vh;
}
@include Responsive(tabletandmobile) {
    .Container-body {
        margin-top: var(--navbarlayout-header-height);
    }
}
