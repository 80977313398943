.lazy-loading-Container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100000;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .lazy-loading {
        margin: auto;
        width: 500px;
        height: 500px;
        display: flex;
        img {
            margin: auto;
        }
    }
}
